import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { Link } from 'gatsby';
import { useLocation } from "@reach/router";



const Footer = ({ data, infoPopup }) => {

      const showCrediton = true;

      const { pathname } = useLocation();

      let isHome = pathname == "/" ? true : false;


      return (


            <>

                  {/* FOOTER */}
                  <div className="c-footer o-section">
                        <div className="o-wrapper">
                              <div className="c-footer__col c-footer__col--left">


                                    <a href={showCrediton ? "https://www.crediton.com.uy" : "https://www.creditonplus.com.uy" } target="_blank" className="c-footer__logo">
                                          <span className="u-hidden-for-seo">{data.title}</span>
                                    </a>

                                    <span className="c-footer-link__title">¡Contactanos por WhatsApp!</span>
                                    <a href="#" className="c-footer-link__number c-footer-link__number--wsp  js-whatsapp-btn c-footer-link__data--wsp c-footer-link__data" rel="noreferrer">{showCrediton ? '091 200 055':'099 270 679' }</a>


                                    <span className="c-footer-link__title">Atención al cliente</span>
                                    <a href="tel:+59826049827" className="c-footer-link__number c-footer-link__number--tel c-footer-link__data--tel c-footer-link__data">2604 9827</a>

                                    {showCrediton &&
                                          <a href="mailto:contacto@crediton.com.uy" class="c-footer-link__data c-footer-link__data--mail">contacto@crediton.com.uy</a>
                                    }
                                    {!showCrediton &&
                                          <a href="mailto:contacto@creditonplus.com.uy" class="c-footer-link__data c-footer-link__data--mail">contacto@creditonplus.com.uy</a>
                                    }

                              </div>{/*
                */}<div className="c-footer__col c-footer__col--right">

                                    <Link to={"/facturacion-electronica"} className="c-footer-link__btn">
                                          Factura electrónica
                                    </Link>

                                    <a className="c-footer-link__btn js-btn-financiera">Información financiera</a>

                                    <Link to={"/buenas-practicas"} className="c-footer-link__btn">
                                          Código de buenas prácticas
                                    </Link>
                                    
                                    <div className="c-footer-social">
                                         {!showCrediton && <a href="https://www.facebook.com/CreditonPlus " className="c-footer-social__link icon-facebook" target="_blank" rel="noreferrer" title="Visita nuestro Facebook" />}
                                          <a href={` ${showCrediton ? 'https://www.instagram.com/crediton_uy/?hl=es-la ' : 'https://www.instagram.com/creditonplus/?hl=es-la' } `} className="c-footer-social__link icon-instagram" target="_blank" rel="noreferrer" title="Visita nuestro Instagram" />
                                          {showCrediton &&<a href="https://www.youtube.com/c/CreditonUy " className="c-footer-social__link icon-youtube" target="_blank" rel="noreferrer" title="Visita nuestro Youtube" />}
                                    </div>
                              </div>
                              <p className="c-footer__text">
                                    <RichText render={data.text} />
                              </p>
                        </div>{/* .o-wrapper */}
                  </div>{/* .c-footer */}
                  <div className="c-footer__disclaimer">

                        <div className="c-footer__disclaimer-holder o-wrapper">
                              {showCrediton &&<a href="https://www.creditonplus.com.uy/" className="c-footer-disclaimer__logo" target="_blank" rel="noreferrer" title="Visita la página de Crediton Plus" />}
                              {!showCrediton &&<a href="https://www.crediton.com.uy/" className="c-footer-disclaimer__logo" target="_blank" rel="noreferrer" title="Visita la página de Crediton" />}
                              <p className="c-footer__copyright c-footer__copyright-text">© {new Date().getFullYear()}  CREDITON {!showCrediton && 'PLUS'}. Todos los Derechos Reservados. - San Lucar 1560. Montevideo, Uruguay.</p>

                              <Link to={"/terminos-y-condiciones"} className="c-footer__copyright-text c-footer__copyright-link">
                                    Términos y Condiciones
                              </Link>

                        </div>{/* .c-footer__disclaimer-holder */}
                  </div>{/* .c-footer__disclaimer */}
                  <div className="c-credits">
                        <div className="c-credits__holder o-wrapper">
                              <ul className="c-credits__list">
                                    <li className="c-credits__item">
                                          Diseño:
                <a title="Nimbo" className="c-credits__link" target="_blank" href="https://www.nimbo.com.uy/" rel="noreferrer">
                                                <span className="u-hidden-for-seo">Nimbo</span>
                                                <span className="c-credits__img c-credits__img--nimbo" />
                                          </a>
                                    </li>
                                    <li className="c-credits__item">
                                          Desarrollo Web:
                <a title="Bloque - Estudio de diseño y desarrollo web" className="c-credits__link" target="_blank" href="https://www.bloque.com.uy/" rel="noreferrer">
                                                <span className="u-hidden-for-seo">Bloque</span>
                                                <span className="c-credits__img c-credits__img--bloque" />
                                          </a>
                                    </li>
                              </ul>{/* .c-credits */}
                        </div>
                  </div>


                  {/* POP-UP BODY  */}
                  <div className="c-info js-popup-financiera">
                        <div className="c-info__holder">
                              <span className="c-info__close js-close-info" />
                              <h2 className={`c-title c-title--small c-title--blue ${showCrediton && 'c-title--red'}`}>Información financiera</h2>


                              {infoPopup.body.map((field, i) => (
                                    <div className="c-info__data">
                                          <h3 className="c-info__title c-title--blue">{field.primary.titulo}</h3>
                                          {field.primary.descripcion && <span className="c-info__text c-text">{field.primary.descripcion}</span>}
                                          <div className="c-info__table">
                                                <table>
                                                      <tbody>

                                                            <tr>
                                                                  <th>Perfil del Cliente</th>
                                                                  {field.fields.map((item, i) => (
                                                                        <th>{item.cuotas}</th>
                                                                  ))}
                                                            </tr>

                                                            <tr>
                                                                  <td>A</td>
                                                                  {field.fields.map((item, i) => (
                                                                        <td>{item.valor_a}%</td>
                                                                  ))}
                                                            </tr>
                                                            <tr>
                                                                  <td>B</td>
                                                                  {field.fields.map((item, i) => (
                                                                        <td>{item.valor_b}%</td>
                                                                  ))}
                                                            </tr>
                                                            <tr>
                                                                  <td>C</td>
                                                                  {field.fields.map((item, i) => (
                                                                        <td>{item.valor_c}%</td>
                                                                  ))}
                                                            </tr>
                                                            <tr>
                                                                  <td>D</td>
                                                                  {field.fields.map((item, i) => (
                                                                        <td>{item.valor_d}%</td>
                                                                  ))}
                                                            </tr>
                                                            <tr>
                                                                  <td>E</td>
                                                                  {field.fields.map((item, i) => (
                                                                        <td>{item.valor_e}%</td>
                                                                  ))}
                                                            </tr>
                                                            <tr>
                                                                  <td>F</td>
                                                                  {field.fields.map((item, i) => (
                                                                        <td>{item.valor_f}%</td>
                                                                  ))}
                                                            </tr>


                                                      </tbody></table>
                                          </div>
                                    </div>

                              ))}

                              <p className="c-client c-text">
                                    <RichText render={infoPopup.aclaracion} />
                              </p>
                        </div>
                  </div>


            </>
      )
}



Footer.propTypes = {
      data: PropTypes.object,
      infoPopup: PropTypes.object
}


export default Footer;
